import data from "../../data/index.json";
import { MarkGithubIcon } from '@primer/octicons-react';

/*import Social from "./IconBar";*/

export default function MyPortfolio() {
  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div className="portfolio--container-box">
        <div className="portfolio--container">
          <h2 className="section--heading">My Portfolio</h2>
        </div>
        <div>
        <a
      href="https://github.com/AbdoAnany"
      target="_blank"
      rel="noopener noreferrer"
      className="github-link"
    >
      <MarkGithubIcon size={32} />
    </a>
        </div>
      </div>
      <div className="portfolio--section--container">
  {data?.portfolio?.map((item, index) => (
    <div key={index} className="portfolio--section--card">
      <div className="portfolio--section--img">
        <img src={item.src} alt="Placeholder" />
      </div>
      <div className="portfolio--section--card--content">
        <div>
          <h3 className="portfolio--section--title">{item.title}</h3>
          <p className="text-md">{item.description}</p>
        </div>

        <a href={item.link} target="_blank" rel="noopener noreferrer" className="portfolio-link">
          <p className="text-sm portfolio-text">
            {item.btn}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 20 19"
              fill="none"
              style={{ marginLeft: '8px' }} // Adjust the margin as needed
            >
              <path
                d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
                stroke="currentColor"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </p>
        </a>
      </div>
    </div>
  ))}
</div>

    </section>
  );
}
