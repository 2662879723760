import { Link } from "react-scroll";
import React from "react";

function Footer() {
  return (
    <footer className="footer--container">
      <div className="footer--link--container">
      <div className="image-container">
        <img src="./img/Logo.png" alt="Logoipsum" className="logo-img" />
      </div>
        <div className="footer--items">
          <ul>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="heroSection"
                className="text-md"
              >
                Home
              </Link>
            </li>
         
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="AboutMe"
                className="text-md"
              >
                About Me
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="MyPortfolio"
                className="text-md"
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Contact"
                className="text-md"
              >
                Contact
              </Link>
            </li>
            {/* <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="testimonial"
                className="text-md"
              >
                Testimonials
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="footer--social--icon">
          <ul>
            <li>
              <a
                href="https://play.google.com/store/apps/dev?id=7244326600634444734"
                className="navbar--content"
                target="_blank"
                rel="noreferrer"
              >
            <div className="image-container">
        <img src="./img/google-play.png" alt="" className="logo-img" />
      </div>
              </a>
            </li>
            <li>

          
              <a
               href="https://github.com/AbdoAnany"
                className="navbar--content"
                target="_blank"
                rel="noreferrer"
              >
               <div className="image-container">
        <img src="./img/github.png" alt="" className="logo-img" />
      </div>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/Abdo__anany"
                className="navbar--content"
                target="_blank"
                rel="noreferrer"
              >
                 <div className="image-container">
        <img src="./img/twitter.png" alt="" className="logo-img" />
      </div>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/abd-elrahman-anany-8132071b1/"
                className="navbar--content"
                target="_blank"
                rel="noreferrer"
              >
                  <div className="image-container">
        <img src="./img/linkedin.png" alt="" className="logo-img" />
      </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="divider" />
      <div className="footer--content--container">
        <p className="footer--content">Made with 💖 by Abdelrahman Anany</p>
        <div className="footer--social--icon">
          <ul>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Privacy_Policy"
                className="text-sm"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Terms_of_Service"
                className="text-sm"
              >
                Terms of Service
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Cookies_Settings"
                className="text-sm"
              >
                Cookies Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
