import { useState, useEffect } from "react";
import { Link } from "react-scroll";
   // ,
    // {
    //   "id": "7",
    //   "src": "./img/jjmage.png",
    //   "title": "Miraath",
    //   "description": "Miraath is a comprehensive Islamic educational platform focused on the science of inheritance (Al-Fara'id). The site emphasizes the importance of learning and teaching inheritance laws, which are well-documented in the Quran and Hadith.",
    //   "link": "https://apps.apple.com/eg/app/%D9%85%D9%88%D8%A7%D8%B1%D9%8A%D8%AB/id1489030767?l=ar&fbclid=IwAR2xquzeQETni2G1yXoQFR3s0J1qJY_4qKRsRY9CNEJFYKRp6s03gQroSW0",
    //   "btn": "App Store"
    // }
function Navbar() {
  const [navActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu;
    }
  }, []);

  return (
    <nav className={`navbar ${navActive ? "active" : ""}`}>
    <div className="image-container">
        <img src="./img/logo.svg" alt="Logoipsum" className="logo-img" />
      </div>
      <a
        className={`nav__hamburger ${navActive ? "active" : ""}`}
        onClick={toggleNav}
      >
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="heroSection"
              className="navbar--content"
            >
              Home
            </Link>
          </li>
         
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="AboutMe"
              className="navbar--content"
            >
              About Me
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="MyPortfolio"
              className="navbar--content"
            >
              Portfolio
            </Link>
          </li>
     
                 <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Contact"
                className="text-md"
              >
                Contact
              </Link>
            </li>


            <li>
            <Link
  to="#" // or any other valid route
  onClick={(e) => {
    e.preventDefault();
    window.open("https://drive.google.com/file/u/0/d/1BPAUQVC15DQ021R_03zxnddgWXsoRwGf/view?usp=sharing&pli=1", "_blank");
    closeMenu();
  }}
  className="navbar--content"
>
  Resume
</Link>
          </li>
        </ul>
        
      </div>
      <a
  href="https://drive.google.com/file/u/0/d/1BPAUQVC15DQ021R_03zxnddgWXsoRwGf/view?usp=sharing&pli=1"
  target="_blank"
  rel="noopener noreferrer"
  className="btn btn-outline-primary"
  onClick={closeMenu}
>
  Resume
</a>

    </nav>
  );
}

export default Navbar;
