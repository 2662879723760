export default function AboutMe() {
  
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.webp" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">

          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
          I'm Abdelrahman Anany, a  Software developer and Programmer.
          </p>
          <p className="hero--section-description">
    
During my journey as a software developer,
 I've had the enriching experience of contributing to a 
 variety of projects, allowing me to refine my skills
  in mobile programming and problem-solving. Among the array of technologies that I've engaged with, 
Flutter development holds a distinctive and cherished position in my professional repertoire.
          </p>

        <h3> Email:  eng.abdelrahman.anany@gmail.com</h3>

        <a
  href="https://drive.google.com/file/u/0/d/1BPAUQVC15DQ021R_03zxnddgWXsoRwGf/view?usp=sharing&pli=1"
  target="_blank"
  rel="noopener noreferrer"
  className="btn btn-outline-primary"

>
  Resume
</a>
        </div>
      </div>
    </section>
  );
}
