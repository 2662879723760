import { Link } from "react-scroll";

export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hey, I'm Abdelrahman Anany</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Software</span>{" "}
            <br />
            Developer
          </h1>
          <p className="hero--section-description">
          Mastering the art of software development , and programming.
          </p>
        </div>
        <h></h>

        <div className="hero--section-description">
        <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Contact"
                className="text-md"
              >
              <button className="btn btn-primary">Get In Touch</button>
              </Link>

        </div>
    


      </div>
      
      <div className="hero--section--img">
        <img src="/img/hero_img.svg" alt="Hero Section" />
      </div>
      
    </section>
  );
}
